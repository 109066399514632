import React, { useRef, useEffect, useState } from "react";
import "./App.css";
import "./App_responsive.css";
import "./Menu.css";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import logo from "./assets/logo.svg";
import koser from "./assets/crc_kosher_white.png";
import img1 from "./assets/Photo 1.jpg";
import img2 from "./assets/Photo 2.jpg";
import img3 from "./assets/Photo 3.jpg";
import imageLeft from "./assets/1-salad-bg.png";
import circle7 from "./assets/3 Romaine - 1.png";
import circle8 from "./assets/3 Romaine - 2.png";
import circle9 from "./assets/3 Romaine - 3.png";
import mix3 from "./assets/3-salad-bg.png";
import packaging3 from "./assets/Cleanest-&-Greenest_Romaine.png";
import footerLogo from "./assets/footer-logo.png";
import Fade from "react-reveal/Fade";
import herovideo from "./assets/bg.mp4";
import image1 from "./assets/no-wash.png";
import image2 from "./assets/no-pesticides.png";
import image3 from "./assets/longest-shelf-life.png";
import salad from './assets/3-salad-bg.png'

function App() {
  let firstSaladStart = useRef(null);
  let secondSaladStart = useRef(null);
  let secondSaladEnd = useRef(null);
  let thirdSaladStart = useRef(null);
  let thirdSaladEnd = useRef(null);

  const [isLoading, setIsLoading] = useState(true);
  const [showMenu, setShowMenu] = useState(false);
  const [showMenuOpacity, setShowMenuOpacity] = useState(false);

  const body = document.querySelector("body");

  const clickov = () => {
    if (showMenu) {
      setShowMenuOpacity(false);
      body.style.overflow = "auto";
      body.style.marginRight = "0";
      setTimeout(() => {
        setShowMenu(false);
      }, 400);
    } else {
      setShowMenu(true);
      setTimeout(() => {
        body.style.overflow = "hidden";
        // body.style.marginRight= '16.5px'
        setShowMenuOpacity(true);
      }, 0);
    }
  };

  useEffect(() => {
    setTimeout(function () {
      const el = document.querySelector(".loader-container");
      if (el) {
        el.remove();
      }
    }, 1500);

    setTimeout(function () {
      setIsLoading(false);
    }, 2200);
    if (typeof window !== "undefined") {
      gsap.registerPlugin(ScrollTrigger);

      gsap.to(".firstImage", {
        x: 8,
        y: -35,
        rotation: 3,
        scrollTrigger: {
          trigger: firstSaladStart.current,
          markers: false,
          start: "-800 0",
          scrub: 1,
          duration: 5,
        },
      });

      gsap.to(".first-bg-salad", {
        x: 10,
        y: -70,
        rotation: 0.5,
        scrollTrigger: {
          trigger: firstSaladStart.current,
          markers: false,
          start: "-800 0",
          scrub: 1,
          duration: 5,
        },
      });

      gsap.to(".first-textScrollAnimation", {
        x: -8,
        y: -100,
        scrollTrigger: {
          trigger: firstSaladStart.current,
          markers: false,
          start: "-800 0",
          scrub: 1,
          duration: 5,
        },
      });

      gsap.to(".second-textScrollAnimation", {
        x: 8,
        y: -100,
        scrollTrigger: {
          trigger: secondSaladStart.current,
          markers: false,
          start: "-800 0",
          scrub: 1,
          duration: 5,
        },
      });

      gsap.to(".third-textScrollAnimation", {
        x: -8,
        y: -120,
        scrollTrigger: {
          trigger: thirdSaladStart.current,
          markers: false,
          start: "-800 0",
          scrub: 1,
          duration: 5,
        },
      });

      gsap.to(".secondImage", {
        x: -8,
        y: -35,
        rotation: -3,
        scrollTrigger: {
          trigger: secondSaladStart.current,
          markers: false,
          start: "-800 0",
          scrub: 1,
          duration: 5,
        },
      });

      gsap.to(".second-bg-salad", {
        x: -10,
        y: -70,
        rotation: -0.5,
        scrollTrigger: {
          trigger: secondSaladStart.current,
          markers: false,
          start: "-600 0",
          scrub: 1,
          duration: 5,
        },
      });

      gsap.to(".thirdImage", {
        x: 8,
        y: -35,
        rotation: 3,
        scrollTrigger: {
          trigger: thirdSaladStart.current,
          markers: false,
          start: "-800 0",
          scrub: 1,
          duration: 5,
        },
      });

      gsap.to(".third-bg-salad", {
        x: 10,
        y: -80,
        rotation: 0.5,
        scrollTrigger: {
          trigger: thirdSaladStart.current,
          markers: false,
          start: "-800 0",
          scrub: 1,
          duration: 5,
        },
      });
    }
  }, []);

  return (
    <div className="App">
      <div className="main-container">
        {/* Hero with nav */}
        <div className="hero">
          {showMenu && (
            <div
              className={`menu-open ${
                showMenuOpacity ? "menu-open-active" : ""
              }`}
            >
              <div className="menu-open-elements">
                <a href="/"> <img src={salad} alt="salad" className="image-salad"/></a>
                <div>
                  <a href="/find-us">find us</a>
                </div>
                <div>
                  <a href="/our-greens">our greens</a>
                </div>
                <div>
                  <a href="/our-farm">our farm</a>
                </div>
                <div>
                  <a
                    href={"https://www.instagram.com/cleanestandgreenest/"}
                    target={"_blank"}
                  >
                    instagram
                  </a>
                </div>
              </div>
            </div>
          )}
          <div
            className={`menu-button ${showMenuOpacity && "menu-button-close"}`}
            onClick={() => clickov()}
          >
            {!showMenuOpacity ? "MENU" : "CLOSE"}
          </div>

          <video autoPlay playsInline muted loop id="myVideo">
            <source src={herovideo} type="video/mp4" />
          </video>
          <nav className="nav">
            <Fade delay={400} duration={500}>
              <h3 className="menu-left-container">
                <a href="/our-greens">OUR GREENS</a>
                <a href="/find-us">FIND US</a>
              </h3>
            </Fade>
            <Fade delay={400} duration={500}>
              <img src={logo} alt="logo" className="logo" />
            </Fade>
            <Fade delay={400} duration={500}>
              <h3>
                <a href="/our-farm">OUR FARM</a>
              </h3>
            </Fade>
          </nav>
          <Fade delay={1300} duration={2000}>
            <h1>The future of Kosher greens</h1>
          </Fade>
        </div>
        {/* Bellow hero */}
        <div className="about">
          <div>
            <img
              duration={2000}
              src={mix3}
              alt="salad"
              className="first-salad"
            />

            <h4>THE CLEANEST AND GREENEST DIFFERENCE</h4>

            <h2>Certified Kosher greens like you've never tasted</h2>
          </div>
          <div className="facts">
            <Fade delay={800} duration={2000}>
              <div className="circleContainer">
                <img alt="circle" src={image1} />{" "}
                <h3>
                  No need to wash <br className="br" /> or check
                </h3>{" "}
                <p>
                  Grown in sealed environments exponentially reducing any chance
                  of bugs.
                </p>
              </div>
            </Fade>
            <Fade delay={950} duration={2000}>
              <div className="circleContainer">
                <img alt="circle" src={image2} />{" "}
                <h3>
                  No pesticides <br className="br" />
                  or GMOs
                </h3>{" "}
                <p>No harmful sprays or additives. Keep your family safe.</p>
              </div>
            </Fade>
            <Fade delay={1200} duration={2000}>
              <div className="circleContainer">
                <img alt="circle" src={image3} />{" "}
                <h3>
                  The freshest
                  <br className="br" /> greens
                </h3>{" "}
                <p>
                  Our greens last longer and taste better for weeks, not days.
                </p>
              </div>
            </Fade>
          </div>
        </div>
        {/* The process */}
        <div className="people-saying">
          <Fade delay={500} duration={2000}>
            <h4>WHAT PEOPLE ARE SAYING</h4>
          </Fade>
          <Fade delay={500} duration={2000}>
            <h2>
              “Kosher greens in the past have always been disappointing for me.
              I can't believe how fresh and flavorful the C&G products are.”
            </h2>{" "}
          </Fade>

          <div className="process-row-left-orientated" ref={thirdSaladStart}>
            <div className="process-row-left-orientated-left">
              <div className="third-bg-salad">
                <img
                  alt="packaging"
                  className="package thirdImage"
                  src={packaging3}
                />
              </div>
            </div>
            <div className="process-row-left-orientated-right third-textScrollAnimation">
              <Fade delay={300} duration={1500}>
                <h3 className="mix-title">Our greens</h3>

                <p className="mix-description">
                  We nurture our greens every step of the way, ensuring that
                  they’re incredibly clean, nutritionally dense, safe, and of
                  course, the most delicious they could possibly be.
                </p>
              </Fade>
              <div className="three-purple-images">
                <Fade delay={300} duration={1500}>
                  <img alt="circle1" src={circle7} />
                </Fade>
                <Fade delay={400} duration={1500}>
                  <img alt="circle2" className="circle2" src={circle8} />
                </Fade>
                <Fade delay={500} duration={1500}>
                  <img alt="circle3" src={circle9} ef={secondSaladEnd} />
                </Fade>
              </div>
              <Fade delay={600} duration={1500}>
                <a className={"our-greens-button"} href={"/our-greens"}>
                  SEE OUR GREENS
                </a>
              </Fade>
            </div>
          </div>
        </div>

        {/*  100% Kosher */}
        <div id="kosher" className="kosher" ref={thirdSaladEnd}>
          <Fade delay={300} duration={1500}>
            <img alt="kosher_icons" src={koser} />
          </Fade>

          <Fade delay={400} duration={1500}>
            <h2>100% KOSHER</h2>
          </Fade>

          <Fade delay={500} duration={1500}>
            <h4>
              We are dedicated to growing varieties of greens unparalleled in
              taste, freshness, and cleanliness to bring a new Kosher culinary
              experience for you to share around your table.
            </h4>
          </Fade>

          {/*           <Fade delay={600} duration={1500}>
            <p className="certification-margin-kosher">
              *Certification in process with multiple Hashgachos
            </p>
          </Fade> */}
        </div>
        {/*  3 SALAD PICS */}
        <div className="three-salads">
          <Fade delay={300} duration={1500}>
            <h4>WHAT PEOPLE ARE SAYING</h4>
            <h2>
              "C&G has built their indoor farm from the ground up with Kosher
              produce in mind. The cleanliness and flavor are incredible. I've
              never seen anything like it."
            </h2>
          </Fade>
          <div className="three-salads-images">
            <Fade delay={300} duration={1500}>
              <div className="image_container">
                <img alt="img1" src={img1} />
              </div>
            </Fade>

            <Fade delay={400} duration={1500}>
              <div className="image_container">
                <img alt="img2" src={img2} />
              </div>
            </Fade>
            <Fade delay={500} duration={1500}>
              <div className="image_container">
                <img alt="img3" src={img3} />
              </div>
            </Fade>
          </div>
        </div>

        {/*  Promise */}
        <div className="promise">
          <div className="promise-left">
            <Fade duration={1500}>
              <img alt="left" className="promise-left-img" src={imageLeft} />
            </Fade>
          </div>

          <div className="promise-right">
            <Fade delay={300} duration={1500}>
              <h2>The Cleanest and Greenest promise</h2>
            </Fade>
            <Fade delay={400} duration={1500}>
              <h4>
                From day one, we've been on a mission to create an environment
                where there is no need to wash or check.{" "}
              </h4>
            </Fade>

            <Fade delay={300} duration={1500}>
              <p>
                Our technology-enhanced systems have made this a reality,
                enabling us to grow indoors 365 days a year with full
                traceability and transparency from seed, to harvest, to shelf.{" "}
              </p>
            </Fade>
            <Fade delay={400} duration={1500}>
              <a className={"our-greens-button-black"} href={"/our-greens"}>
                SEE OUR GREENS
              </a>
            </Fade>

            {/* <Fade delay={300} duration={1500}>
              <p className="certification-margin">
                *Certification in process with multiple Hashgachos
              </p>
            </Fade> */}

            {/*  <Fade delay={400} duration={1500}>
              {' '}
              <img
                alt="kosher"
                className="promise-left-koser-img"
                src={koserWhite}
              />
            </Fade> */}
          </div>
        </div>
      </div>

      {isLoading ? null : (
        <footer>
          <div className="footer-main">
            <div className="footer-left">
              <img height="100px" src={footerLogo} alt="footer-logo" />{" "}
              <p className="under-footer-logo-text">
                Cleanest and Greenest is a frum-owned and operated vertical
                farming company dedicated to growing varieties of Kosher greens
                that are unparalleled in taste and cleanliness.
              </p>
            </div>
            <div className="footer-right">
              <div className="footer-contact">
                <h5>Contact</h5>
                <p>
                  <a href="mailto:contact@cleanestandgreenest.com">
                    contact@cleanestandgreenest.com
                  </a>{" "}
                  <br /> 1717 East-West Road <br /> Calumet City, IL{" "}
                </p>
              </div>

              <div className="footer-follow">
                <h5>Follow us</h5>
                <p>
                  <a
                    href={"https://www.instagram.com/cleanestandgreenest/"}
                    target={"_blank"}
                  >
                    Instagram
                  </a>{" "}
                  <br />{" "}
                  <a
                    target={"_blank"}
                    href={"https://www.facebook.com/cleanestandgreenest"}
                  >
                    {" "}
                    Facebook{" "}
                  </a>{" "}
                  <br />
                </p>
              </div>

              <div className="footer-legal">
                <h5>Legal</h5>
                <p>
                  Privacy Policy <br /> Terms and Conditions
                </p>
              </div>
            </div>
          </div>
          <p className="copyright">© 2022 Cleanest and Greenest</p>
        </footer>
      )}
    </div>
  );
}
export default App;
